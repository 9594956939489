import * as moment from 'moment';
import classNames from 'classnames';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { CheckSquare, ChevronDown, ChevronUp, Package, Square } from 'react-feather';
import { Link } from 'react-router-dom';
import { Badge, Button, ButtonGroup, Col, Collapse, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Address, CopyToClipboard, DateFormat, DateDiffFormat, Tracking } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { OrderedProduct, ReplaceOrderForm } from '../../../../components/Admin/Order';
import { CustomerNotes } from '../../../../components/CustomerNotes/CustomerNotes';
import { EmailEditor } from '../../../../components/EmailEditor/EmailEditor';
import { DeliveredForm } from './DeliveredForm';
import { NewTracking } from './NewTracking';
import { TrackingForm } from './TrackingForm';
import { VendorFulfillmentTime } from './VendorFulfilmentTime';

const MODAL_DELIVERED = 'modal_delivered';
const MODAL_EMAIL = 'modal_email';
const MODAL_TRACKING = 'tracking';
const MODAL_REPLACE = 'replace';

const OrderComponent = ({
  order,
  selected,
  onSelect,
  onUpdate,
  onAck,
  onSyncTracking,
  onFetchTracking,
  products,
  onReplace,
  showFulfillmentTime,
  addToast,
  onSyncBottomlessTracking,
  updateNotes,
}) => {
  const [checked, setChecked] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);
  const [isCollapsed, collapse] = useToggle(true);

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  const onClick = e => {
    e.stopPropagation();
    setChecked(!checked);

    if (onSelect) {
      onSelect(order);
    }
  };

  const onUpdateTracking = async data => {
    await onUpdate(order._id, data);
    onSyncTracking(order._id);
  };

  const markAsSupport = async (_id, needSupport) => {
    if (needSupport) {
      await onUpdate(_id, { need_support: false });
      addToast('Support resolved');
    } else {
      await onUpdate(_id, { need_support: true });
      addToast('Order marked as support');
    }
  };

  const markAsReplacementQueue = async (_id, markAsRep) => {
    if (markAsRep) {
      await onUpdate(_id, { tracking_status: 'replacement' });
      addToast('Added to replacement queue');
    } else {
      await onUpdate(_id, { tracking_status: '' });

      onSyncBottomlessTracking(_id);
      addToast('Removed from replacement queue');
    }
  };

  const user = order.user_id;
  const latestTracking = useMemo(
    () => [...(order.tracking_updates || [])].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).shift(),
    [order]
  );

  const toggle = type => {
    setType(type);
    setOpen(!isOpen);
  };

  return (
    <div className={classNames('tracked-order', { 'd-none': hidden, 'border-bottom': !hidden })}>
      <div className="p-2">
        <Row className="justify-content-between mb-2">
          <Col>
            <Link
              to={`/admin/ordering_cockpit/${user._id}?orderId=${order._id}&back=admin/order_tracking/${order.subvendor_id._id}`}
              className="text-info text-sm"
            >
              {order._id} / {order.status} / {order.source} / {get(order, 'shipping_status', '---')}
            </Link>

            <span className="text-sm ml-2 text-info">
              <DateFormat date={order.updated_at} withTime />
            </span>
          </Col>
          <Col>
            <ButtonGroup size="sm">
              <Button onClick={() => toggle(MODAL_EMAIL)} color="info" className="mb-1">
                Notifications
              </Button>
              {order.tracking_status !== 'replacement' ? (
                <Button onClick={() => markAsReplacementQueue(order._id, true)} color="warning" className="mb-1">
                  Replacement Queue
                </Button>
              ) : (
                <>
                  <Button onClick={() => toggle(MODAL_REPLACE)} color="danger" className="mb-1">
                    Replace order
                  </Button>
                  <Button onClick={() => markAsReplacementQueue(order._id, false)} color="warning" className="mb-1">
                    Remove from Replacement queue
                  </Button>
                </>
              )}

              {order.tracking_number && !['delivered'].includes(order.shipping_status) && (
                <>
                  <Button onClick={() => toggle(MODAL_DELIVERED)} color="primary" size="sm" className="mb-1">
                    Delivered
                  </Button>

                  <Button
                    className="d-flex justify-content-center align-items-center mb-1"
                    size="sm"
                    onClick={() => onSyncTracking(order._id)}
                  >
                    <span className="mr-2">Sync</span>
                    <Package size="13" />
                  </Button>

                  <Button
                    className="d-flex justify-content-center align-items-center mb-1"
                    size="sm"
                    onClick={() => onFetchTracking(order)}
                  >
                    <span className="mr-2">Fetch</span>
                    <Package size="13" />
                  </Button>
                </>
              )}

              {!order.tracking_number && (
                <Button onClick={() => toggle(MODAL_TRACKING)} color="primary" size="sm" className="mb-1">
                  Tracking number
                </Button>
              )}
              <Button
                onClick={() => markAsSupport(order._id, order.need_support)}
                color="danger"
                size="sm"
                className="mb-1"
              >
                {order.need_support ? 'Resolve' : 'Support'}
              </Button>
            </ButtonGroup>
          </Col>
          <Col sm="1">
            <Button onClick={onClick} color="transparent" className="btn-sm">
              {checked ? <CheckSquare size="15" /> : <Square size="15" />}
            </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="10">
            <Row>
              {user && (
                <Col>
                  <div>
                    {user.first_name} {user.last_name}
                  </div>
                  <a
                    href={`https://app.frontapp.com/compose?mailto=mailto:${user.local.email}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary"
                  >
                    {user.local.email}
                  </a>
                  <div>
                    <Address inline address={user.verifiedAddress} />
                  </div>
                </Col>
              )}
              <Col>
                <OrderedProduct order={order} user={user} />
              </Col>
              <Col>
                {order.tracking_number && (
                  <div>
                    <p className="mb-0">
                      <span>Est. </span>
                      {latestTracking && (
                        <DateDiffFormat date={latestTracking.est_delivery_date} withColor fromZeroed />
                      )}
                    </p>
                  </div>
                )}
                <div>
                  Shipped: <DateDiffFormat date={order.date_shipped} withColor />
                </div>
                {(showFulfillmentTime ||
                  (order.usps_tracking &&
                    (order.usps_tracking.status === 'pre_transit' || order.usps_tracking.status === 'accepted'))) &&
                  order.subvendor_id &&
                  order.subvendor_id.fulfillment_times && (
                    <div>
                      <VendorFulfillmentTime vendor={order.subvendor_id} date={order.date_fulfilled} />
                    </div>
                  )}
                {order.tracking_number && (
                  <div>
                    <Tracking
                      number={order.tracking_number}
                      label=""
                      shippingService={order.shipping_service}
                      trackingUrl={order.tracking_url}
                    />
                  </div>
                )}
                {order.usps_tracking && (
                  <div className="usps-status" onClick={toggle}>
                    New USPS status:{' '}
                    <Badge
                      color={classNames({
                        danger: ['return_to_sender', 'alert'].includes(order.usps_tracking.status),
                        success: order.usps_tracking.status === 'delivered',
                        primary: order.usps_tracking.status === 'in_transit',
                        warning: order.usps_tracking.status === 'pre_transit',
                        info: order.usps_tracking.status === 'accepted',
                        secondary: order.usps_tracking.status === 'unknown',
                      })}
                    >
                      {get(order, 'usps_tracking.status', '---')}
                    </Badge>
                  </div>
                )}
                {order.notificationNotes ? (
                  <>
                    <div>Notification Sent: {order.notificationNotes?.length}</div>
                    <div>
                      Last Notification Date:{' '}
                      {order.notificationNotes[0]?.created_at ? (
                        <DateFormat date={order.notificationNotes[0].created_at} withTime />
                      ) : (
                        '---'
                      )}
                    </div>
                  </>
                ) : (
                  undefined
                )}
                {order.tracking_status && (
                  <div>
                    Tracking Status:{' '}
                    <Badge
                      color={
                        ('success',
                        classNames({
                          danger: [
                            'pre_transit_2',
                            'accepted_2',
                            'in_transit_exceptions',
                            'out_for_delivery_2',
                            'alert_1',
                          ].includes(order.tracking_status),
                        }))
                      }
                    >
                      {order.tracking_status}
                    </Badge>
                  </div>
                )}
                {user.scale_last_connected && <span> {moment(user.scale_last_connected).format('MM/DD/YYYY')}</span>}
                {user.scale_last_weight && (
                  <> - Last weight reported {parseFloat(user.scale_last_weight).toFixed(2)}oz</>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="ml-auto text-right" sm="2">
            <ButtonGroup vertical>
              <Button
                onClick={() => {
                  onAck(order);
                  setHidden(true);
                }}
                color="success"
                size="sm"
                className="mb-1"
              >
                Acknowledge
              </Button>
              <Button
                onClick={collapse}
                color="info"
                size="sm"
                className="d-flex align-items-center justify-content-center"
              >
                <span className="mr-1">Details</span>
                {isCollapsed ? <ChevronDown size="15" /> : <ChevronUp size="15" />}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <NewTracking order={order} onlyFirstTwo={isCollapsed} />
        <Collapse isOpen={!isCollapsed}>
          <Row className="pt-3">
            <Col>
              <CustomerNotes order={order} />
            </Col>
          </Row>
        </Collapse>
      </div>

      {isOpen && (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
          {modalType === MODAL_DELIVERED && (
            <>
              <ModalHeader toggle={toggle}>Mark as delivered</ModalHeader>
              <ModalBody>
                <DeliveredForm
                  onSubmit={data => {
                    onUpdate(order._id, data);
                    toggle();
                  }}
                  order={order}
                />
              </ModalBody>
            </>
          )}

          {modalType === MODAL_EMAIL && (
            <>
              <ModalHeader toggle={toggle}>Send notification</ModalHeader>
              <ModalBody>
                <EmailEditor
                  isNotification={true}
                  context={{ orderId: order._id, userId: order.user_id._id }}
                  onSuccess={() => {
                    onAck(order);
                    toggle();
                    updateNotes();
                  }}
                  source="order_tracking"
                />
              </ModalBody>
            </>
          )}

          {modalType === MODAL_TRACKING && (
            <>
              <ModalHeader toggle={toggle}>Tracking number</ModalHeader>
              <ModalBody>
                <TrackingForm order={order} onSubmit={onUpdateTracking} onSuccess={toggle} />
              </ModalBody>
            </>
          )}

          {modalType === MODAL_REPLACE && (
            <>
              <ModalHeader toggle={toggle}>
                <div>Order to be replaced</div>
                <div className="text-sm text-secondary">
                  {order._id} <CopyToClipboard text={order._id} />
                </div>
              </ModalHeader>
              <ModalBody>
                <ReplaceOrderForm
                  products={products}
                  order={order}
                  onSubmit={data => onReplace(order._id, { ...data, isTracking: true })}
                  onSubmitSuccess={toggle}
                />
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

OrderComponent.propTypes = {
  selected: PropTypes.bool,
  products: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  onAck: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  onSyncTracking: PropTypes.func.isRequired,
  onFetchTracking: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    need_support: PropTypes.bool,
    source: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    shipping_status: PropTypes.string.isRequired,
    shipping_service: PropTypes.string.isRequired,
    tracking_url: PropTypes.string,
    notificationNotes: PropTypes.array,
    user_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      scale_last_connected: PropTypes.string,
      scale_last_weight: PropTypes.number,
      verifiedAddress: PropTypes.object.isRequired,
      local: PropTypes.shape({
        email: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    product_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.string.isRequired,
    }).isRequired,
    subproduct_id: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        variants: PropTypes.array.isRequired,
      }).isRequired,
    }),
    tracking_updates: PropTypes.array,
    tracking_status: PropTypes.string,
    last_tracking_update: PropTypes.string,
    tracking_number: PropTypes.string,
    subvendor_id: PropTypes.object,
    address: PropTypes.object,
    date_initiated: PropTypes.string.isRequired,
    override_fulfillment_date: PropTypes.string,
    date_fulfilled: PropTypes.string,
    date_shipped: PropTypes.string,
    predictions: PropTypes.array,
    notes: PropTypes.array,
    usps_tracking: PropTypes.shape({
      status: PropTypes.string,
      summary: PropTypes.array,
    }),
  }).isRequired,
  showFulfillmentTime: PropTypes.bool,
  onSyncBottomlessTracking: PropTypes.func.isRequired,
  updateNotes: PropTypes.func,
};

export const Order = memo(OrderComponent);
